<template>
	<button :disabled="disabled" class="btn" v-bind:class="[line ? 'button--line-'+color : 'button--'+color, small? 'btn-sm' : '']" @click="clicked" data-bs-toggle="tooltip" :title="tooltipTitle" data-trigger="hover">
		<div class="d-flex align-items-center justify-content-center">
			<i v-if="icon" :class="[icon,!hide && title ? 'mr-2' : '']"></i>
			<span class="button--title" v-bind:class="{'hide_btn':hide}">{{title}}</span>
			<span v-if="tag" class="badge badge--red-rf ml-2">{{tag}}</span>
		</div>
	</button>
</template>
<script type="text/javascript">
	export default {
		name: 'Button',
		props:{
			title:'',
			icon: null,
			line:false,
			color:'',
			tooltipTitle:'',
			disabled:false,
			hide:false,
			tag:null,
			small:false
		},
		mounted(){
		},
		methods:{
			clicked(){
				this.$emit('action');
			},
		}
	}
</script>
<style>
	.btn {
		width: auto;
		align-items: center;
		padding: 10px;
		font-weight: bold;
	}
	.hide_btn {
		white-space: nowrap;
		width: 0;
		overflow: hidden;
		align-items: center;
		margin-left: 0;
		transition: width 0.1s ease;
	}
	.btn:hover .hide_btn {
		width: 100%;
		margin-left: 0.5rem;
		transition: width 0.3s ease;
	} 
	.button--gray {
		background: var(--gray-rf);
		color: #FFF;
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-gray {
		background: transparent;
		color: #000;
		border:1px solid var(--gray-rf);
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-gray:hover {
		background: var(--gray-rf);
		color: #FFF;
	}
	.button--green {
		background: var(--green-rf);
		color: #FFF;
		font-size: 14px;
		border-radius: 10px;
	}
	.button--green:hover {
		background: var(--green-rf);
	}
	.button--line-green {
		background: transparent;
		color: var(--green-rf)!important;
		border:1px solid var(--green-rf);
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-green:hover {
		background: #3FD47844;
		color: var(--green-rf)!important;
	}
	.button--purple {
		background: var(--purple-rf);
		color: #FFF;
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-purple {
		background: transparent;
		color: var(--purple-rf)!important;
		border:1px solid var(--purple-rf);
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-purple:hover {
		background: #B374F644;
		color: var(--purple-rf)!important;
	}
	.button--blue {
		background: var(--blue-rf);
		color: #FFF;
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-blue {
		background: transparent;
		color: var(--blue-rf)!important;
		border:1px solid var(--blue-rf);
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-blue:hover{
		background: #0E78F944!important;
		color: var(--blue-rf)!important;
	}
	.button--red {
		background: var(--red-rf);
		color: #FFF;
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-red {
		background: transparent;
		color: var(--red-rf)!important;
		border:1px solid var(--red-rf);
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-red:hover{
		background: #DB235644;
		color: var(--red-rf)!important;
	}
	.button--yellow {
		background: var(--yellow-rf);
		color: #FFF;
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-yellow {
		background: transparent;
		color: var(--yellow-rf)!important;
		border:1px solid var(--yellow-rf);
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-yellow:hover{
		background: #FFC40044;
		color: var(--yellow-rf)!important;
	}
	.button--line-orange {
		background: transparent;
		color: var(--orange-rf)!important;
		border:1px solid var(--orange-rf);
		font-size: 14px;
		border-radius: 10px;
	}
	.button--line-orange:hover{
		background: #FF573344;
		color: var(--orange-rf)!important;
	}
	.button-group .btn {
		border-radius: 0px;
		display: block;
	}

	.btn:hover {
		filter: brightness(0.85);
		color: #fff;
	}
	.btn:focus {
		box-shadow: none;
	}
	.button--title {
/*		text-shadow: -.5px 0 var(--gray-rf), 0 .5px var(--gray-rf), .5px 0 var(--gray-rf), 0 -.5px var(--gray-rf);*/
	}
</style>