<template>
	<div>
		<div class="ms-5 d-flex">
			<div><span class="page-title">Lojas</span></div>
			<div class="ms-auto me-5">
				<Button color="green" title="Cadastrar loja" :line="true" @action="newStore()" />
			</div>
		</div>
		<div class="mt-4">
			<FormInputSearch class="w-50" v-model="search" @updateModel="filter()" placeholder="Procure UID, Nome ou E-mail" />
		</div>
		<div class="mt-3">
			<GenericTable ref="GenericTable"
			identifier="accounts"
			:source="getSource()"
			:columns="columns"
			:tableClass="'table'"
			:tableStyle = '"font-size:14px"'
			:perPages = "[10, 20, 30]"
			/>
		</div>
		<ModalNewStore ref="ModalNewStore" />
	</div>
</template>
<script>
	import Button from '@/components/Button'
	import GenericTable from '@/components/GenericTable'
	import * as StoreServices from '@/services/StoreServices';
	import ModalNewStore from './ModalNewStore'
	import FormInputSearch from '@/components/FormInputSearch'

	export default {
		name: "Stores",
		components: {Button, GenericTable, ModalNewStore, FormInputSearch },

		data(){
			return {
				columns: [
					new Utils.GenericTableColumn('uid', "UID", false,),
					new Utils.GenericTableColumn('name', "Nome", false,),
					new Utils.GenericTableColumn('email', "E-mail", false,),
					new Utils.GenericTableColumn('subscription', "Assinatura", false,null, 'center'),
					new Utils.GenericTableColumn('payment', "Último Pagamento", false,null,'center'),
					new Utils.GenericTableActionColumn("",this.tableActions,null, this.tooltipsTable,'fa-regular fa-pen-to-square', 'fa-regular fa-trash-can', 'fa-solid fa-money-bill-transfer')
					],
				stores: [],
				nextPageToken: null,
				search: null
			}
		},

		methods:{
			getSource() {
				return this;
			},
			numOfRows() {
				return this.stores.length;
			},
			templateForRow(column, r) {
				var row = this.stores[r];
				if(column == 0) {
					return '<div><span style="text-align:center"><strong>' + row.uid + '</strong></span></div>';
				} else if(column == 1) {
					return '<div><span><strong>' + row.name + '</strong></span><div>';
				} else if(column == 2) {
					return '<div><span><strong>' + row.email + '</strong></span></div>';
				} else if(column == 3) {
					return '<div><span><strong><i class="fa-regular fa-user"></i> ' + row.subscription.drivers + ' - R$' + Utils.formatMoney(row.subscription.value_in_cents/100) + '</strong></span></div>';
				} else if(column == 4) {
					let now = moment();
					let lastPayment = moment(row.lastPayment);
					let dueDate = moment();
					dueDate.date(row.subscription.due_day);

					if(row.lastPayment && lastPayment.isSame(now, 'month') && lastPayment.isSame(now, 'year')){
						return '<div><span class="badge rounded-pill badge--green-rf">' + moment(row.lastPayment).format('DD/MM/YYYY') + '</span></div>';
					} else if(row.lastPayment && dueDate.isBefore(now)) {
						return '<div><span class="badge rounded-pill badge--red-rf">' + moment(row.lastPayment).format('DD/MM/YYYY') + '</span></div>';
					} else if(dueDate.isBefore(now)) {
						return '<div><span class="badge rounded-pill badge--red-rf">Aguardando pagamento</span></div>';
					} else {
						return '<div><span class="badge rounded-pill badge--yellow-rf">Aguardando pagamento</span></div>';
					}
				}
				return '';
			}, 
			loadData(page,perPage,sortColumn,sortOrder,resolve) {
				var self = this;
				StoreServices.loadStores(perPage, this.nextPageToken, this.search).then(function(result){
					if (result.length){
						self.stores.push(...result);
						self.nextPageToken = result[result.length - 1].uid;
						resolve(result);
					} else{
						resolve();
					}

				}).catch((err)=> {
					Utils.showDialogError(err);
				});
			},
			newStore(store = null){
				let self = this;
				this.$refs.ModalNewStore.showModal(store).then(()=>{
					self.filter();
				});
			},
			removeStore(store) {
				let self = this;
				Utils.showDialogConfirm(`Deseja mesmo remover a loja <strong>${store.name}</strong>?`, 'Remover').then(()=>{
					Utils.showLoading();
					StoreServices.removeStore(store.uid, store.secret).then(function(result){
						self.filter();
						Utils.closeLoading();
						Utils.showSnackbarSuccess(result.data.message);
					}).catch((err)=> {
						Utils.closeLoading();
						Utils.showDialogError(err.message);
					});
				}).catch(()=>{})
			},
			tooltipsTable(button){
				if(button == 0){
					return 'Editar conta';
				} else if(button == 1) {
					return 'Excluir conta';
				}
			},
			tableActions(button, row) {
				if(button == 0) {
					this.newStore(this.stores[row]);
				} else if(button == 1) {
					this.removeStore(this.stores[row]);
				} else if(button == 2) {
					StoreServices.pay(this.stores[row].uid);
				}
			},
			filter(){
				this.stores = [];
				this.nextPageToken = null;
				this.$refs.GenericTable.refreshData();
			}
		}
	}
</script>