<template>
	<div style="height: 100%;" class="content">
		<GenericMap ref="GenericMap" :callback="mapDone" width="100%" height="100%" border="0"
		style="height: 100%;width: 100%;">
	</GenericMap>

	<div :class="['toggle-button', { open: isSidebarOpen }]" @click="toggleSidebar">
		<div :class="['bar', { open: isSidebarOpen }]"></div>
		<div :class="['bar', { open: isSidebarOpen }]"></div>
		<div :class="['bar', { open: isSidebarOpen }]"></div>
	</div>
	<div :class="['sidebar', { open: isSidebarOpen }]" class="card-rf d-flex flex-column">
		<div v-if="loading">
			<div class="mt-2 infos">
				<div class="w-100">
					<div class="ms-3 text-start">
						<div class="info-name placeholder-glow">
							<span class="placeholder col-7"></span>
						</div>
						<div class="info-email placeholder-glow">
							<span class="placeholder col-9"></span>
						</div>
					</div>
				</div>
				<div class="btn-logout">
					<button class="btn" title="Sair" data-bs-toggle="tooltip" v-on:click="logout()">
						<i class="fa-solid fa-right-from-bracket"></i>
					</button>
				</div>
			</div>
			<div class="mt-4 list-drivers">
				<div class="list-drivers-item" v-for="driver in 5">
					<div class="placeholder-glow text-start">
						<div class="placeholder col-12">
						</div>
					</div>
					<div class="placeholder-glow text-start" style="font-size: 12px;">
						<div class="placeholder col-7">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div>
				<div class="infos">
					<div>
						<div class="info-name">
							<span>{{ name }}</span>
						</div>
						<div class="info-email">
							{{ emailUser }}
						</div>
					</div>
					<div class="btn-logout">
						<button class="btn" title="Sair" data-bs-toggle="tooltip" v-on:click="logout()">
							<i class="fa-solid fa-right-from-bracket"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="mt-4 list-drivers">
				<div class="list-drivers-item" v-for="driver in driversSorted">
					<div class="d-flex align-items-center justify-content-start ">
						<div class="text-nowrap text-container">
							{{ driver.full_name }}
						</div>
						<div class="ms-2" style="font-size:8px; cursor: pointer;">
							<i v-if="driverIsOnline(driver)" style="color: var(--green-rf)" class="fa-solid fa-circle" title="Online" data-bs-toggle="tooltip"></i>
							<i v-else style="color: var(--red-rf)" class="fa-solid fa-circle" title="Offline" data-bs-toggle="tooltip" data-bs-trigger="hover"></i>
						</div>
						<div class="ms-auto d-flex align-items-center list-drivers-item-btn">
							<button v-if="driverIsOnline(driver)" class="btn" title="Localizar colaborador" data-bs-toggle="tooltip" v-on:click="focusOnMark(driver.uid)">
								<i class="fa-solid fa-location-crosshairs"></i>
							</button>
							<button class="btn" title="Chamar no WhatsApp" data-bs-toggle="tooltip" v-on:click="callInWhatsApp(driver.phone)">
								<i class="fa-brands fa-whatsapp"></i>
							</button>
							<button class="btn" title="Editar colaborador" data-bs-toggle="tooltip" data-bs-trigger="hover" v-on:click="newDriver(driver)">
								<i class="fa-solid fa-pen-to-square"></i>
							</button>
						</div>
					</div>
					<div class="d-flex align-items-center justify-content-start" style="color:var(--dark-gray-rf); font-size: 12px;">
						<span>Útima atualização:</span>
						<span class="ms-1">{{ getUpdateAt(driver.uid) }}</span>
					</div>
				</div>
			</div>

		</div>
		<div class="mt-auto sidebar-buttons">
			<div class="line" style="margin-bottom:10px"></div>
			<Button class="w-100" title="Adicionar colaborador" color="green" :line="true" @action="newDriver()" />
			<Button class="mt-2 w-100" title="Configurações" color="blue" :line="true" @action="openConfig()" />
		</div>
	</div>
	<div class="home-buttons">
		<div>
			<button class="btn" title="Mostrar seu local" data-bs-toggle="tooltip" v-on:click="focusOnHome()">
				<i class="fa-solid fa-location-crosshairs"></i>
			</button>
		</div>
		<div>
			<button class="btn" title="Mostrar todos" data-bs-toggle="tooltip" v-on:click="focusToMarks()">
				<i class="fa-solid fa-map-location-dot"></i>
			</button>
		</div>
	</div>
	<div>
		<ModalNewDriver ref="ModalNewDriver" />
		<ModalConfig ref="ModalConfig" 
		:secret="secret" 
		:uid="uid"
		:name="name"
		:dueDay="subscription.dueDay"
		:valuePayment="subscription.value"
		:lastPayment="lastPayment"
		:drivers="subscription.drivers" />
		<ModalPayPix />
	</div>
</div>
</template>
<script>
	import * as MainServices from '@/services/MainServices.js'
	import GenericMap from '@/components/GenericMap.vue'
	import logo from '@/assets/images/rj_logo_1.png'
	import Button from '@/components/Button'
	import MotoboyDelivery from '@/assets/images/house_marker.png'
	import MotoboyImage from '@/assets/images/motoboy_marker.png'
	import ModalNewDriver from '@/views/Dashboard/ModalNewDriver'
	import ModalConfig from '@/views/Dashboard/ModalConfig'
	import * as LoginServices from '@/services/LoginServices.js'
	import { currentUser } from '@/database/firebase';
	import ModalPayPix from '@/views/Payment/ModalPayPix'

	export default {
		name: "Main",
		components: { GenericMap, Button, ModalNewDriver, ModalConfig, ModalPayPix },

		data() {
			return {
				logo: logo,
				emailUser: null,
				drivers: [],
				locations: {},
				motoboyImg: MotoboyImage,
				home: {
					lat: null,
					lng: null,
					id: Utils.generateId(),
					homeImg: MotoboyDelivery,
				},
				secret: null,
				name: null,
				uid: null,
				tooltipList: [],
				loading: false,
				isSidebarOpen: false,
				subscription: {
					drivers: null,
					dueDay: null,
					value:null
				},
				lastPayment: null,

			}
		},
		mounted() {
			var self = this;
			Utils.showLoading();
			this.loading = true;
			if (!currentUser) {
				EventBus.$on('LOGGED-IN', (data) => {
					self.emailUser = data.email;
					self.uid = data.uid;
					self.$refs.GenericMap.loadMap();
					Utils.closeLoading();
				});
			} else {
				this.emailUser = currentUser.email;
				this.uid = currentUser.uid;
				this.$refs.GenericMap.loadMap();
				Utils.closeLoading();
			}

			EventBus.$on('LOGGED-OUT', (data) => {
				Utils.closeLoading();
			});
		},
		beforeDestroy() {
			EventBus.$off('LOGGED-IN');
			EventBus.$off('LOGGED-OUT');
			this.tooltipList.forEach((tooltip) => {
				tooltip.dispose();
			});
		},
		computed: {
			driversSorted() {
				if (!this.locations || this.isObjectEmpty(this.locations)) {
					return this.drivers.sort((a, b) => {
						if (a.full_name > b.full_name) {
							return 1;
						}
						if (a.full_name < b.full_name) {
							return -1;
						}
						return 0;
					});
				}
				let drivers = this.drivers.sort((a, b) => {
					if (!this.locations[a.uid]) {
						return -1;
					}
					if (!this.locations[b.uid]) {
						return -1;
					}
					if (this.locations[a.uid].online) {
						if (this.locations[b.uid].online) {
							if (a.full_name > b.full_name) {
								return 1;
							}
							if (a.full_name < b.full_name) {
								return -1;
							}
						}
						return -1;
					}
					return 1;
				})
				return drivers;
			}
		},
		methods: {
			isObjectEmpty(obj) {
				return Utils.isObjectEmpty(obj);
			},
			driverIsOnline(driver) {
				let locations = JSON.parse(JSON.stringify(this.locations))
				if (!locations || Utils.isObjectEmpty(locations))
					return false;
				if (!locations[driver.uid])
					return false;

				return locations[driver.uid].online;
			},
			getUpdateAt(uid) {
				let locations = JSON.parse(JSON.stringify(this.locations))
				if (!locations || Utils.isObjectEmpty(locations))
					return null;
				if (!locations[uid])
					return null;
				let update = moment(locations[uid].updatedAt);
				if (update.isSame(moment(), "day"))
					return update.format("HH:mm");

				return update.format('DD/MM/YYYY HH:mm');
			},
			mapDone() {
				this.$refs.GenericMap.map.mapTypeControl = false;
				this.$refs.GenericMap.map.fullscreenControl = false;
				this.$refs.GenericMap.clearMap();
				this.getData();
			},
			logout() {
				let self = this;
				LoginServices.signOut().then(() => {
				}).catch((error) => {
					Utils.showDialogError(error);
				})
			},
			async getData() {
				try {
					await MainServices.loadInformations(this.updateInformations);
					await MainServices.loadDrivers(this.updateDrivers);
					await MainServices.loadLocations(this.updateLocations);
					await MainServices.loadSubscription(this.updateSubscription);

				} catch(error) {
					console.error(error);				
				}
				this.loading = false;
				this.enableTooltips();			
			},
			updateDrivers(result) {
				let self = this;
				return new Promise((resolve, reject) => {
					if (result && !Utils.isObjectEmpty(result)) {
						this.drivers = Object.keys(result).map(function (key) {
							return {
								'full_name': result[key].full_name,
								'password': result[key].password,
								'uid': result[key].uid,
								'phone': key,
							}
						});
					}
					this.enableTooltips();
					resolve();
				})
			},
			updateLocations(result) {
				return new Promise((resolve, reject) => {
					this.locations = result;
					if (!this.$refs.GenericMap)
						return;
					for (const uid in this.locations) {
						let marker = this.$refs.GenericMap.findMarker(uid);
						if (this.locations[uid].online) {
							if (marker != null && (marker.position().lat() != this.locations[uid].lat || marker.position().lng() != this.locations[uid].lng)) {
								this.$refs.GenericMap.setMarkerPosition(uid, this.locations[uid].lat, this.locations[uid].lng);

							} else if (!marker) {
								this.$refs.GenericMap.addMaker(
									this.locations[uid].lat,
									this.locations[uid].lng,
									this.drivers.find((driver) => driver.uid == uid).full_name,
									uid,
									null
									);
							}
						} else if (marker) {
							this.$refs.GenericMap.removeMarker(uid);
						}
					}
					this.enableTooltips();
					resolve();
				})

			},
			focusOnMark(uid) {
				this.$refs.GenericMap.focusOnMark(uid, 16);
				this.toggleSidebar();
			},
			enableTooltips() {
				if (!Utils.isMobile()) {
					this.$nextTick(() => {
						const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
						const options = {
							'animation': true,
							'trigger': 'hover'
						};
						this.tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, options));
					})
				}
			},
			focusOnHome() {
				this.$refs.GenericMap.focusOnMark(this.home.id, 16);
			},
			focusToMarks() {
				this.$refs.GenericMap.focusToMarks();
			},
			newDriver(driver = null) {
				if(driver == null && this.subscription.drivers == this.drivers.length) {
					Utils.showDialogError("Você não pode adicionar mais colaboradores. Verifique sua assinatura.")
				} else {
					this.$refs.ModalNewDriver.showModal(driver);
				}
			},
			updateInformations(result) {
				return new Promise((resolve, reject) => {
					this.name = result.name;
					this.secret = result.secret;
					this.home.lat = result.lat;
					this.home.lng = result.lng;
					this.lastPayment = moment(result.last_payment_date).format('DD/MM/YYYY');
					let marker = this.$refs.GenericMap.findMarker(this.home.id);
					if (!marker) {
						this.$refs.GenericMap.addMaker(
							this.home.lat,
							this.home.lng,
							this.name,
							this.home.id,
							this.home.homeImg
							);
					} else {
						this.$refs.GenericMap.setMarkerPosition(this.home.id, this.home.lat, this.home.lng, this.home.homeImg);
					}
					this.$refs.GenericMap.focusOnMark(this.home.id, 14);
					resolve();
				})
			},
			openConfig() {
				this.$refs.ModalConfig.showModal();
			},
			callInWhatsApp(phone) {
				window.open(`https://wa.me/+55${phone}`, '_blank').focus();

			},
			toggleSidebar() {
				this.isSidebarOpen = !this.isSidebarOpen;
			},
			updateSubscription(result) {
				return new Promise((resolve, reject) => {
					this.subscription.drivers = result.drivers;
					this.subscription.dueDay = result.due_day;
					this.subscription.value = Utils.formatMoney(result.value_in_cents/100);
					resolve();
				})
			},
		}
	}
</script>
<style>
	.sidebar {
		width: 300px;
		position: absolute;
		z-index: 999;
		bottom: 0;
		top: 0;
		left: 0;
		margin: 5px;
		padding: 0 0 10px 0;
		font-weight: bold;
	}

	.sidebar .infos {
		display: flex;
		padding: 20px;
		background-color: #5E230B;
		color: #FFF;
		text-align: left;
	}

	.sidebar .info-name {
		font-size: 18px;
		font-weight: bold;
	}

	.sidebar .info-email {
		font-size: 14px;
		font-weight: normal;
/*		color: var(--dark-gray-rf);*/
	}

	.sidebar .infos .btn-logout {
		margin-left: auto;
	}

	.sidebar .infos .btn-logout button {
		color: var(--orange-rf);
	}

	.list-drivers {
		overflow-y: scroll;
	}

	.list-drivers-item {
		padding: 10px;
		border-radius: 10px;
		margin: 10px;
	}

	.list-drivers-item:hover {
		background: #8080802e;
	}

	.list-drivers-item-btn button {
		font-size: 14px;

	}

	.list-drivers-item-btn button:hover {
		color: var(--orange-rf);
	}
	.sidebar .sidebar-buttons {
		padding: 10px;
	}

	.home-buttons {
		position: absolute;
		z-index: 999;
		bottom: 10px;
		left: 350px;
	}

	.home-buttons button {
		background: #ff5733ad;
		border-radius: 1.7rem;
		border: 1px solid var(--orange-rf);
		margin: 10px;
		padding: .75rem;
		font-size: 21px;
		line-height: 0;
	}

	.home-buttons button:hover {
		background: var(--orange-rf);

	}

	.line {
		background-image: radial-gradient(circle at center, #4242426e, transparent);
		height: 1px;
		width: 300px;
		box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
	}

	img[alt="Google"] {
		margin-left: 300px !important;
	}

	.text-container {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.toggle-button {
		display: inline-block;
		cursor: pointer;
		margin: 15px;
		z-index: 999;
		position: absolute;
		top: 0;
		left: 0;
		background-color: var(--bonina-rf);
		padding: 0.5rem;
		border-radius: .75rem;
	}

	.toggle-button .bar {
		width: 35px;
		height: 4px;
		background-color: var(--orange-rf);
		margin: 6px 0;
		transition: 0.4s;
		border-radius: 2px;

	}

	.toggle-button .bar.open {
		position: absolute;
	}

	.toggle-button .bar.open:nth-child(1) {
		transform: rotate(45deg);
		top: 10px;
	}

	.bar.open:nth-child(2) {
		opacity: 0;
	}

	.bar.open:nth-child(3) {
		transform: rotate(-45deg);
		top: 10px;
	}

	.toggle-button.open {
		background-color: transparent;
		top: 10px;
	}

	@media (max-width: 768px) {
		.sidebar {
			width: 100%;
			position: fixed;
			transform: translateX(-100%);
			transition: transform 0.3s ease-in-out;
			z-index: 998;
			margin: 0px;
			border-radius: 0;
		}

		.sidebar:hover {
			width: 100%;
		}
		.sidebar.open>div:first-child>div:first-child{
			background-color: var(--bonina-rf);
		}

		.sidebar .infos {
			margin-left: 60px;
		}

		.home-buttons {
			left: 10px;
			bottom: 60px;
			z-index: 997;
		}

		.sidebar.open {
			transform: translateX(0);
		}

		.home-buttons button {
			padding: 1rem;
			font-size: 18px;
		}
	}
</style>