import { functions, httpsCallable } from '@/database/firebase';

export function loadAccounts(perPage, nextPageToken, search){
	return new Promise((resolve, reject)=>{
		const getAccounts = httpsCallable(functions, 'getAccounts');

		getAccounts({'perPage':perPage, 'nextPageToken': nextPageToken, 'search': search}).then((result)=>{
			resolve(result);
		}).catch((err)=>{
			reject(err);
		})
	})
}

export function createAccount(name, email, password, role){
	return new Promise((resolve, reject)=>{
		const createAccount = httpsCallable(functions, 'createAccount');

		createAccount({
			'displayName':name, 
			'email': email,
			'password': password,
			'role': role
		}).then((result)=>{
			resolve(result);
		}).catch((err)=>{
			reject(err);
		})
	})
}

export function editAccount(uid, name, email, password, role){
	return new Promise((resolve, reject)=>{
		const editAccount = httpsCallable(functions, 'editAccount');

		editAccount({
			'uid': uid,
			'displayName':name, 
			'email': email,
			'password': password,
			'role': role
		}).then((result)=>{
			resolve(result);
		}).catch((err)=>{
			reject(err);
		})
	})
}

export function removeAccount(uid){
	return new Promise((resolve, reject)=>{
		const deleteAccount = httpsCallable(functions, 'deleteAccount');

		deleteAccount({
			'uid':uid
		}).then((result)=>{
			resolve(result);
		}).catch((err)=>{
			reject(err);
		})
	})
}