<template>
	<Modal ref="Modal">
		<template v-slot:header>
			<span>Lista de pagamentos</span>
		</template>
		<template v-slot:body>
			<div class="accordion" id="accordionExample" style="max-height: fit-content;">
				<div class="accordion-item" v-for="payment in payments">
					<h2 class="accordion-header">
						<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
							<span>
								<strong>{{payment.paymentReference}}</strong>
							</span>

							<span class="ms-2 badge rounded-pill badge--green-rf" v-if="payment.paymentDate"> Pago em {{payment.paymentDate}}</span>
							<span class="ms-2 badge rounded-pill badge--red-rf" v-else>Não pago</span>
						</button>
					</h2>
					<div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
						<div class="accordion-body">
							<div class="d-flex align-items-center justify-content-between">
								<div>
									<span class="badge rounded-pill badge--green-rf">
										<strong class="me-1">Valor: </strong>
										R${{payment.valueInCents}}
									</span>
								</div>
								
								<div class="ms-2" v-if="!payment.paymentDate">
									<Button title="Pagar via PIX" color="orange" :line="true" @action="openPay(payment)" />
								</div>
							</div>
						</div>
					</div>
				</div>	
				<infinite-loading direction="end" @infinite="loadData" :identifier="listId">
					<div slot="spinner"><div class="spinner-border text-danger" role="status"></div></div>
					<div slot="no-more"><span class="font--dark-gray-robin mb-4" style="font-size:14px">Não há mais pagamentos</span></div>
					<div slot="no-results"><span class="font--dark-gray-robin mb-4" style="font-size:14px">Não encontramos nenhum pagamento</span></div>
				</infinite-loading>
			</infinite-loading>	
		</div>
	</template>
</Modal> 
</template>
<script>
	import Modal from '@/components/Modal'
	import InfiniteLoading from 'vue-infinite-loading';
	import * as MainServices from '@/services/MainServices.js'
	import Button from '@/components/Button'

	export default {
		name: "ModaListPayment",
		components: { Modal, InfiniteLoading, Button },

		data(){
			return {
				payments: [],
				nextPageToken: null,
				perPage:10,
				listId: +new Date(),
			}
		},
		methods: {
			showModal(){
				this.payments = [];
				this.nextPageToken = null;
				this.listId += 1;
				this.$refs.Modal.show();
			},
			loadData($state) {
				var self = this;
				MainServices.loadPayments(this.perPage, this.nextPageToken).then(function(result){
					if(result.length){
						self.page += 1;
						self.payments.push(...result);
						self.nextPageToken = result[result.length - 1].uid;
						if($state){
							$state.loaded();
						}
					} else if(result.length != 0) {
						if($state){
							$state.loaded();
							$state.complete();
						}                      
					} else {
						if($state)
							$state.complete();
					}

				}).catch((err)=> {
					Utils.showDialogError(err);
				});
			},
			openPay(payment){
				EventBus.$emit("OPEN_MODAL_PIX");
			}
		}
	}
</script>