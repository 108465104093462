import { initializeApp } from "firebase/app";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import * as FirebaseAuth from "firebase/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB1HW0qrZdJzSVcNvYYSi8KWopICt_x7GI",
    authDomain: "rf-rastreio-c067c.firebaseapp.com",
    projectId: "rf-rastreio-c067c",
    storageBucket: "rf-rastreio-c067c.appspot.com",
    messagingSenderId: "102732630838",
    appId: "1:102732630838:web:d648c5e437e7d1f4174197"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Functions
var functions = null;

export var db = null;

var auth = null;

if (process.env.NODE_ENV == 'development') {
    db = getDatabase();
    connectDatabaseEmulator(db, 'localhost', process.env.VUE_APP_PORT_FIREBASE_DATABASE);
    auth = FirebaseAuth.getAuth();
    FirebaseAuth.connectAuthEmulator(auth, process.env.VUE_APP_PORT_FIREBASE_AUTH);
    functions = getFunctions();
    connectFunctionsEmulator(functions, 'localhost', process.env.VUE_APP_PORT_FIREBASE_FUNCTIONS)
} else {
    db = getDatabase(app);
    auth = FirebaseAuth.getAuth(app);
    functions = getFunctions(app);
}

export { functions, httpsCallable };
export var currentUser = null;

if(auth){
    FirebaseAuth.onAuthStateChanged(auth, ($user)=>{
        if($user){
            currentUser = $user;
            currentUser.getIdTokenResult().then((idTokenResult)=>{
                const claims = idTokenResult.claims;
                if(claims.role != 'admin'){ 
                    _router.push({name: 'dashboard'}).catch(()=>{});
                } else {
                    _router.push({name: 'admin'}).catch(()=>{});
                }
                EventBus.$emit('LOGGED-IN', currentUser);
            });
        } else {
            currentUser = null;
            EventBus.$emit('LOGGED-OUT');
            if(_router.currentRoute.name != 'login'){
                _router.push({name: 'login'}).catch(()=>{});
            }
        }
    })
}