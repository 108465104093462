<template>
    <div>
        <div :id="mapId" v-bind:style="mapStyle()"></div>
    </div>
</template>
<script>
    import { Loader } from '@googlemaps/js-api-loader';
    import Marker from '@/utils/marker.js'
    export default {
        name: 'GenericMap',
        props: {
            callback: {
                type: Function
            },
            width: '',
            height: '',
            border: {
                type: String,
            default: '0.75rem'
            },
        },
        data() {
            return {
                mapId: 'map_' + Utils.generateId(),
                google: null,
                map: null,
                apiKey: 'AIzaSyCbKjsO8wUAhQjcJT6lgx7VAoqY2A6QfXk',
                mapConfig: {
                    center: { lat: 0.00, lng: 0.00 },
                    zoom: 10,
                    gestureHandling: "cooperative",
                    mapTypeControl: true,
                    streetViewControl: true,
                    fullscreenControl: true,
                    zoomControl: true,
                    streetViewControl: false,
                },
                markers: {},
            }
        },
        methods: {
            mapStyle() {
                return {
                    'width': this.width,
                    'height': this.height,
                    'border-radius': this.border,
                }
            },
            loadMap() {
                var self = this;
                const loader = new Loader({
                    apiKey: this.apiKey,
                });
                loader.load().then((google) => {
                    self.google = google;
                    self.initMap();
                })
                .catch(e => {
                    console.log(e);
                });
            },
            initMap() {
                var myStyle = [
                {
                    featureType: "administrative",
                    elementType: "labels",
                    stylers: [
                       { visibility: "on" }
                       ]
                },
                {
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [
                       { visibility: "off" }
                       ]
                },
                {
                    featureType: "water",
                    elementType: "labels",
                    stylers: [
                       { visibility: "off" }
                       ]
                },
                {
                    featureType: "road",
                    elementType: "labels",
                    stylers: [
                       { visibility: "on" }
                       ]
                }
                ];

                this.map = new this.google.maps.Map(document.getElementById(this.mapId), this.mapConfig);
                this.map.mapTypes.set('RobinStyle', new this.google.maps.StyledMapType(myStyle, { name: 'Robin Food' }));

                this.map.set('mapTypeId', 'RobinStyle');

                if (this.callback)
                    this.callback();

            },
            addMaker(lat, lon, title = '', id = null, image = null, _function, animation = false) {
                if(this.google == null) {
                    return;
                }
                if (this.map == null)
                    return;
                const infoWindow = new this.google.maps.InfoWindow();
                var markerData = {
                    position: { lat: parseFloat(lat), lng: parseFloat(lon) },
                    title: title,
                };
                if (image != null) {
                    markerData['icon'] = {
                        url: image,
                        scaledSize: new this.google.maps.Size(50, 50)
                    };
                } else {
                    markerData['icon'] = this.createMarkerIcon(title);
                }
                if (animation) {
                    markerData['animation'] = this.google.maps.Animation.DROP;
                }
                
                var marker = new this.google.maps.Marker(markerData);
                if (_function != null) {
                    marker.addListener("click", _function);
                }
                if(title) {
                    marker.addListener("mouseover", () => {
                        infoWindow.close();
                        infoWindow.setContent(marker.getTitle());
                        infoWindow.open(marker.getMap(), marker);
                    });
                    marker.addListener("mouseout", () => {
                        infoWindow.close();
                    });
                }
                if(!id) {
                    id = Utils.generateId();
                }

                if(this.markers[id]) {
                    this.markers[id].setMap(null);   
                }
                this.markers[id] = new Marker(marker, title);
                marker.setMap(this.map);

                return marker;
            },
            focusToMarks(zoom = null) {
                if (this.map == null){
                    return;
                }

                var latlngbounds = new this.google.maps.LatLngBounds();
                for (const marker in this.markers) {
                    latlngbounds.extend(this.markers[marker].position());
                }

                this.map.panTo(latlngbounds.getCenter());
                if (zoom) {
                    this.map.setZoom(zoom);
                } else {
                    this.map.fitBounds(latlngbounds);
                }
            },
            findMarker(id){
                return this.markers[id];
            },
            removeMarker(id){
                this.markers[id].setMap(null);
                delete this.markers[id];
            },
            clearMap() {
                for (const marker in this.markers) {
                    this.markers[marker].setMap(null);
                }

                this.markers = {};
            },
            ajustZoom(zoom) {
                this.map.setZoom(zoom);
            },
            focusOnMark(id, zoom = null){
                if (this.map == null){
                    return;
                }

                let latlngbounds = new this.google.maps.LatLngBounds();
                latlngbounds.extend(this.markers[id].position());
                this.map.panTo(latlngbounds.getCenter());
                if (zoom) {
                    this.map.setZoom(zoom);
                } else {
                    this.map.fitBounds(latlngbounds);
                }
            },
            setMarkerPosition(markId, lat, lng){
                this.markers[markId].move(parseFloat(lat), parseFloat(lng));
            },
            createMarkerIcon(name) {
                const words = name.split(' ');
                let initials = words[0][0].toUpperCase();
                if(words[1])
                    initials += words[1][0].toUpperCase();

                const canvas = document.createElement('canvas');
                const size = 60;
                canvas.width = size + 10;
                canvas.height = size + 10;
                const context = canvas.getContext('2d');

                context.fillStyle = '#FF5722';
                context.beginPath();
                context.arc(size / 2, size / 2, size / 2 - 5, 0, 2 * Math.PI);
                context.fill();

                context.beginPath();
                context.moveTo(size / 2 - 18, size / 2 + 18);
                context.lineTo(size / 2 + 18, size / 2 + 18);
                context.lineTo(size / 2, size + 5);
                context.closePath();
                context.fill();


                context.fillStyle = 'white'; 
                context.font = 'bold 20px Arial';
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                context.fillText(initials, size / 2, size / 2);

                return {
                    url: canvas.toDataURL(),
                    scaledSize: new google.maps.Size(size, size) 
                };
            }
        }
    }
</script>
<style>
    .gm-ui-hover-effect{
        display: none!important;
    }
</style>