import { ref, onValue, query, get, orderByKey, limitToFirst, startAt, startAfter } from "firebase/database";
import { db, currentUser } from '@/database/firebase';

export function loadDrivers(update){
	return new Promise((resolve, reject)=>{
		const driverRef = ref(db, 'stores/' + currentUser.uid + '/driver');
		onValue(driverRef, (snapshot) => {
			const data = snapshot.val();
			update(data).then(()=>{
				resolve();
			}).catch((err)=>{
				reject(err);
			});
		}, (err) =>{
			reject(err);
		});
	})
}

export function loadLocations(update){
	return new Promise((resolve, reject)=>{
		const locationsRef = ref(db, 'stores/' + currentUser.uid + '/locations');
		onValue(locationsRef, (snapshot) => {
			const data = snapshot.val();
			update(data).then(()=>{
				resolve();
			}).catch((err)=>{
				reject(err);
			});
		}, (err)=>{
			reject(err);
		});
	})
}

export function loadInformations(update){
	return new Promise((resolve, reject)=>{
		const infoRef = ref(db, 'stores/' + currentUser.uid + '/informations');
		onValue(infoRef, (snapshot) => {
			const data = snapshot.val();
			update(data).then(()=>{
				resolve();
			}).catch((err)=>{
				reject(err);
			});
		}, (err)=>{
			reject(err);
		});
	})
}

export function loadSubscription(update){
	return new Promise((resolve, reject)=>{
		const subref = ref(db, 'stores/' + currentUser.uid + '/subscription');
		onValue(subref, (snapshot) => {
			const data = snapshot.val();
			update(data).then(()=>{
				resolve();
			}).catch((err)=>{
				reject(err);
			});
		}, (err)=>{
			reject(err);
		});
	})
}

export function loadPayments(pageSize = 10, nextPageToken = null){
	return new Promise(async (resolve, reject)=>{
		var start = null;
		var pageQuery = query(ref(db, 'stores/' + currentUser.uid + '/billing'), orderByKey(), limitToFirst(pageSize));
		if(nextPageToken) {
			pageQuery = query(ref(db, 'stores/' + currentUser.uid + '/billing'), orderByKey(), startAfter(nextPageToken), limitToFirst(pageSize));
		}
		try {
			const snapshot = await get(pageQuery);
			if (snapshot.exists()) {
				const data = snapshot.val();

				let billing = Object.keys(data).map(function(key) {
					return {
						'created': data[key].created_at,
						'paymentReference': data[key].payment_reference,
						'valueInCents': Utils.formatMoney(data[key].value_in_cents/100),
						'uid': key
					}
				});
				
				resolve(billing);
			} else {
				resolve([]);
			}
		} catch (error) {
			reject(error)
		}

	})
}