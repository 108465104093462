<template>
    <div class="generic-table-rf" v-if="sourceData">
        <table :class="tableClass" :style="tableStyle">
            <thead>
                <th v-for="(column,i) in columns" v-bind:style="columnStyle(column)" v-on:click="setSortColumn(column)">      
                    <span v-bind:style="[column.sortable ? {'cursor':'pointer'} : {'cursor':'default'}]">{{column.name}}</span>
                    <i v-if="column.sortable && sortColumn == column.field && sortOrder == 'desc'" class="fa-solid fa-caret-up"></i>
                    <i v-else-if="column.sortable && sortColumn == column.field && sortOrder == 'asc'" class="fa-solid fa-caret-down"></i>
                </th>
            </thead>
            <tbody>
                <tr v-for="r in rowCount">
                    <td v-for="(column,i) in columns" v-bind:style="rowStyle(column)">
                        <div v-if="isGenericTableColumn(column)" v-on:click="action(column,r-1)" v-html="sourceData.templateForRow(i,r-1)">
                        </div>
                        <div v-else-if="isGenericTableActionColumn(column)">
                            <button v-if="conditionButton(column, r-1, index)" class="btn btn-icon icon-action mr-2" data-bs-toggle="tooltip" data-trigger="hover" v-bind:title="tolltipButton(column, index)" v-for="(icon,index) in column.icons" v-on:click="actionButton(column, r-1,index)">
                                <i v-bind:class="icon"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <infinite-loading direction="end" @infinite="loadData" :identifier="listId">
            <div slot="spinner"><div class="spinner-border text-danger" role="status"></div></div>
        </infinite-loading>
    </div> 
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading';
    export default {
        name: 'GenericTable',
        components: { InfiniteLoading },
        props:{
            source: Object,
            tableClass:'',
            tableStyle:'',
            perPages: {
                type : Array,
            default: ()=>[10,20,30,40,50,100]
            },
            columns: [],
            noCount: {
                type: Boolean,
            default: false
            },
            hidePaginate: {
                type: Boolean,
            default: false
            },
            identifier: {
                type: String,
            default: null
            }
        },
        computed: { 
            rowCount: function() {
                return this.sourceData.numOfRows();
            },
        },
        data(){
            return {
                datas: [],
                perPage:10,
                sortColumn: null,
                sortOrder:'asc',
                sourceData:this.source,
                memoryLoaded: false,
                page: 1,
                listId: +new Date(),
            }
        },
        watch: {
            sortColumn: function() {
                if(this.identifier) {
                    localStorage.setItem('column_'+this.identifier,this.sortColumn);
                }
            },
            sortOrder: function() {
                if(this.identifier) {
                    localStorage.setItem('sort_'+this.identifier,this.sortOrder);
                }
            },
            source: function() {
                this.sourceData = this.source;
            },
        },
        mounted() {
            this.memoryLoaded = false;
            this.loadMemory();
        },
        methods: {
            isGenericTableColumn(column) {
                return column instanceof Utils.GenericTableColumn;
            },
            isGenericTableActionColumn(column) {
                return column instanceof Utils.GenericTableActionColumn;
            },
            loadMemory(){
                if(this.identifier) {
                    this.sortColumn = localStorage.getItem('column_'+this.identifier);
                    this.sortOrder = localStorage.getItem('sort_'+this.identifier);
                    if(!this.sortOrder)
                        this.sortOrder = 'asc';
                    if(this.sortColumn != null) {
                        var has = false;
                        for(var i=0; i < this.columns.length; i++) {
                            if(this.columns[i].field == this.sortColumn) {
                                has = true;
                                break;
                            }
                        }
                        if(!has) {
                            this.sortColumn = null;
                            this.sortOrder = 'asc';
                        }
                    }
                    this.memoryLoaded = true;        
                }
            },
            loadData($state) {
                if(this.sourceData == null)
                    return;
                if(!this.memoryLoaded)
                    this.loadMemory();
                var self = this;
                this.sourceData.loadData(this.page, this.perPage, this.sortColumn, this.sortOrder, function(result){
                    if(!result){
                        if($state){
                            $state.loaded();
                            $state.complete();
                        }
                    } else if(result.length){
                        self.page += 1;
                        if($state){
                            $state.loaded();
                        }
                    } else if(result.length != 0) {
                        if($state){
                            $state.loaded();
                            $state.complete();
                        }                      
                    }
                    self.refreshTooltip();
                })
            },
            columnStyle(column) {
                return {
                    'width': column.width,
                    'text-align':column.align,
                    'cursor':(column.sortable?'pointer':'default')
                };
            },
            rowStyle(column) {
                return {
                    'width': column.width,
                    'text-align':column.align,
                    'cursor':(column.action?'pointer':'default')
                };
            },
            setSortColumn(column) {
                if(column.sortable) {
                    if(this.sortColumn == column.field) {
                        if(this.sortOrder == 'asc')
                            this.sortOrder = 'desc';
                        else
                            this.sortOrder = 'asc';
                    } else {
                        this.sortOrder = 'asc';
                        this.sortColumn = column.field
                    }

                    this.refreshData();
                }
            },
            action(column, row) {
                if(column.action) {
                    column.action(row);
                }
            },
            actionButton(column, row, buttonIndex) {
                if(column.buttonAction) {
                    // $('[data-toggle="tooltip"]').tooltip('hide');
                    column.buttonAction(buttonIndex, row);
                }
            },
            conditionButton(column, row, buttonIndex) {
                if(column.buttonCondition) {
                    return column.buttonCondition(buttonIndex, row);
                }
                return true;
            },
            tolltipButton(column, buttonIndex) {
                if(column.buttonTooltip) {
                    return column.buttonTooltip(buttonIndex);
                }
                return null;
            },

            numOfRows(){
                return this.sourceData.numOfRows();
            },
            refreshData(){
                this.page = 1;
                this.listId += 1;
            },

            refreshTooltip() {
                if(!Utils.isMobile()) {
                    this.$nextTick(()=>{
                        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
                        const options = {
                            'animation': true,
                            'trigger': 'hover'
                        };
                        this.tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, options));
                    })
                }
            },
        },
    }
</script>
<style type="text/css">
    .btn-icon {
        padding: 0px!important;
    }
</style>