<template>
	<Modal ref="Modal">
		<template v-slot:header>
			<h2><strong>Configurações</strong></h2>
		</template>
		<template v-slot:body>
			<div>
				<div>
					<FormInput title="Nome" :disable="true" v-model="name" />
				</div>
				<div class="d-flex flex-wrap mt-3">
					<FormInput class="m-1" title="Valor da assinatura" :disable="true" prefix="R$" v-model="valuePayment" />
					<FormInput class="m-1" title="Máx. de colaboradores" :disable="true" v-model="drivers" />
					<FormInput class="m-1" title="Dia de vencimento" :disable="true" v-model="dueDay" />
					<FormInput class="m-1" title="Último pagamento" :disable="true" v-model="lastPayment" />
				</div>
			</div>
			<div class="mt-4">
				<Button class="mt-2 mb-2 w-100" title="Lista de pagamentos" color="orange" :line="true"
				@action="openListPayments()" />
				<Button class="mt-2 w-100" title="Login do colaborador" color="blue" :line="true"
				@action="openTutorialLoginDriver()" />
			</div>
			<div>
				<ModalLoginDriver ref="ModalLoginDriver" :secret="secret" :uid="uid" />
				<ModalListPayment ref="ModalListPayment" />
			</div>
		</template>
	</Modal>
</template>
<script>
	import Modal from '@/components/Modal';
	import ModalLoginDriver from '@/views/Dashboard/ModalLoginDriver'
	import Button from '@/components/Button'
	import FormInput from '@/components/FormInput'
	import ModalListPayment from '@/views/Dashboard/ModalListPayment'

	export default {
		name: "ModalConfig",
		components: { Modal, ModalLoginDriver, Button, FormInput, ModalListPayment },
		props: {
			secret: '',
			uid: '',
			name: '',
			dueDay: '',
			lastPayment: '',
			valuePayment: '',
			drivers: '',
		},

		
		methods: {
			showModal(){
				let self = this;

				return new Promise((resolve, reject) =>{
					self.$refs.Modal.show();
				})
			},
			openTutorialLoginDriver() {
				this.$refs.ModalLoginDriver.showModal();
			},
			openListPayments(){
				this.$refs.ModalListPayment.showModal();
			}
		}
	}
</script>
