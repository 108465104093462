import { ref, set, get } from "firebase/database";
import { db, currentUser } from '@/database/firebase';

export function setDriver(name, password, phone, uid = null, oldPhone = null){
	return new Promise((resolve, reject) => {
		if(uid && oldPhone) {
			const driverRef = ref(db, 'stores/' + currentUser.uid + '/driver/' + oldPhone);
			get(ref(db, 'stores/' + currentUser.uid + '/driver/' + phone)).then((snapshot)=>{
				if(snapshot.exists()){
					const data = snapshot.val();
					if(data.uid != uid){
						reject("Já existe um Colaborador com esse número de telefone.");
						return;
					}
				}
				set(driverRef, null).then(()=>{
					const newDriverRef = ref(db, 'stores/' + currentUser.uid + '/driver/' + phone);
					set(newDriverRef, {
						'full_name': name,
						'password': password,
						'uid': uid
					}).then(()=>{
						resolve("Colaborador atualizado com sucesso!");
					}).catch((err)=>{
						reject("Erro ao atualizar o Colaborador.");
					});
				}).catch((err)=>{
					reject("Erro ao atualizar o Colaborador.");
				})
			})
		} else {
			const newDriverRef = ref(db, 'stores/' + currentUser.uid + '/driver/' + phone);
			get(newDriverRef).then((snapshot)=>{
				if(snapshot.exists()){
					reject("Já existe um Colaborador com esse número de telefone.");
				} else {
					set(newDriverRef, {
						'full_name': name,
						'password': password,
						'uid': Utils.generateId()
					}).then(()=>{
						resolve("Colaborador criado com sucesso!");
					}).catch((err)=>{
						reject("Erro ao criar um Colaborador.");
					});
				}
			})

		}
	})
}
export function removeDriver(phone){
	return new Promise((resolve, reject) => {
		const driverRef = ref(db, 'stores/' + currentUser.uid + '/driver/' + phone);
		set(driverRef, null).then(()=>{
			resolve("Colaborador apagado com sucesso!");
		}).catch((err)=>{
			reject("Erro ao apagar o Colaborador.");
		});
	})
}