<template>
	<Modal ref="Modal">
		<template v-slot:header>
			<span class="modal-title"><strong>{{title}}</strong></span>
			<Button class="ms-2" title="Salvar" color="green" @action="save()" :line="true" />
		</template>
		<template v-slot:body>
			<div>
				<div class="d-flex">
					<FormInput title="Nome da Loja" class="me-1 flex-fill" v-model="name" />
				</div>
				<div class="d-flex mt-2">
					<FormInput class="me-1 flex-fill" title="Email" type="email" v-model="email" />
					<FormInput class="ms-1 flex-fill" title="Senha" type="password" v-model="password" />
				</div>
				<div class="d-flex mt-2">
					<FormInput class="me-1 flex-fill" title="Latitude" v-model="lat" />
					<FormInput class="ms-1 flex-fill" title="Longitude" v-model="lng" />
				</div>
				<div class="d-flex mt-2">
					<FormInput class="me-1 flex-fill" title="Quantidade de colaboradores" :minValue="0" type="number" v-model="drivers" />
					<FormInput class="ms-1 flex-fill" title="Mensalidade" :money="true" prefix="R$" v-model="value" />
					<FormInput class="ms-1 flex-fill" title="Dia de vencimento" :minValue="0" :maxValue="31" type="number" v-model="dueDay" />
				</div>
			</div>
		</template>
	</Modal>
</template>
<script>
	import Modal from "@/components/Modal"
	import FormInput from '@/components/FormInput'
	import FormSelect from '@/components/FormSelect'
	import Button from '@/components/Button'
	import * as StoreServices from '@/services/StoreServices';

	export default {
		name: "ModalNewStore",
		components: { Modal, FormInput, Button, FormSelect },

		data(){
			return {
				name: null,
				email: null,
				password: null,
				lat: null,
				lng: null,
				_resolve: null,
				_reject: null,
				uid: null,
				title: "Nova Loja",
				drivers: 0,
				value: 0,
				dueDay: 0,
			}
		},
		methods:{
			showModal(store = null){
				let self = this;
				if(store){
					this.name = store.displayName;
					this.email = store.email;
					this.password = null;
					this.name = store.name;
					this.lat = store.lat;
					this.lng = store.lng;
					this.uid = store.uid;
					if(store.subscription) {
						this.drivers = store.subscription.drivers;
						this.value = Utils.formatMoney(store.subscription.value_in_cents/100);
						this.dueDay = store.subscription.due_day;
					}
				}
				return new Promise((resolve, reject)=>{
					self.$refs.Modal.show();
					self._resolve = resolve;
					self._reject = reject;
				});		
			},
			save() {
				if(this.uid){
					this.edit();
				} else{
					this.create();
				}
			},
			create(){
				let self = this;
				this.$refs.Modal.showLoading();
				let value = parseInt(parseFloat(this.value)*100);
				StoreServices.createStore(this.name, this.email, this.password, this.lat, this.lng, this.drivers, value, this.dueDay).then((result)=>{
					self.$refs.Modal.closeLoading();
					self.$refs.Modal.hide();
					Utils.showSnackbarSuccess(result.data.message);
					self.clear();
					if(self._resolve)
						self._resolve();
				}).catch((err)=>{
					self.$refs.Modal.closeLoading();
					Utils.showDialogError(err.message);
				});
			},
			edit(){
				let self = this;
				let value = parseInt(parseFloat(this.value)*100);
				this.$refs.Modal.showLoading();
				StoreServices.editStore(this.uid, this.name, this.email, this.password, this.lat, this.lng, this.drivers, value, this.dueDay).then((result)=>{
					self.$refs.Modal.closeLoading();
					self.$refs.Modal.hide();
					Utils.showSnackbarSuccess(result.data.message);
					self.clear();
					if(self._resolve)
						self._resolve();
				}).catch((err)=>{
					self.$refs.Modal.closeLoading();
					Utils.showDialogError(err.message);
				});
			},
			clear(){
				this.name = null;
				this.email = null;
				this.password = null;
				this.lat = null;
				this.lng = null;
				this.uid = null;
				this.drivers = 0;
				this.value = Utils.formatMoney(0);
				this.dueDay = 0;
			}
		}
	}
</script>