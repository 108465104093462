
import '@/utils/jquery'

import '@popperjs/core'

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap;

import EventBus from '@/utils/event-bus.js'
window.EventBus = EventBus;

import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'

import "@/assets/css/rf.css"

import * as Utils from '@/utils/Utils.js'
window.Utils = Utils;

import * as bootbox from 'bootbox'
window.bootbox = bootbox;

import * as Snackbar from 'node-snackbar/src/js/snackbar.js'
import 'node-snackbar/dist/snackbar.css'
window.Snackbar = Snackbar;

import * as moment from 'moment'
window.moment = moment;
