import { functions, httpsCallable, db } from '@/database/firebase';
import { ref, query, get, orderByKey, limitToFirst, startAt, startAfter } from "firebase/database";

export function loadStores(pageSize = 10, nextPageToken = null, search = null){
	return new Promise(async (resolve, reject)=>{
		var start = null;
		var pageQuery = query(ref(db, "stores"), orderByKey(), limitToFirst(pageSize));
		if(nextPageToken) {
			pageQuery = query(ref(db, "stores"), orderByKey(), startAfter(nextPageToken), limitToFirst(pageSize));
		}
		try {
			const snapshot = await get(pageQuery);
			if (snapshot.exists()) {
				const data = snapshot.val();

				let stores = Object.keys(data).map(function(key) {
					return {
						'lat': data[key].informations.lat,
						'lng': data[key].informations.lng,
						'name': data[key].informations.name,
						'uid': key,
						'email': data[key].informations.email,
						'secret': data[key].informations.secret,
						'subscription': data[key].subscription,
						'lastPayment': data[key].informations.last_payment_date,
					}
				});
				if(search) {
					stores = stores.filter((store)=>{
						return store.email.toLowerCase().includes(search.toLowerCase()) ||
						store.name.toLowerCase().includes(search.toLowerCase()) ||
						store.uid.toLowerCase().includes(search.toLowerCase());
					})
				}
				
				resolve(stores);
			} else {
				resolve([]);
			}
		} catch (error) {
			reject(error)
		}

	})
}

export function createStore(name, email, password, lat, lng, drivers, value, dueDay){
	return new Promise((resolve, reject)=>{
		const createStore = httpsCallable(functions, 'createStore');

		createStore({
			'name':name, 
			'email': email,
			'password': password,
			'lat': lat,
			'lng': lng,
			'drivers': drivers,
			'valueInCents': value,
			'dueDay': dueDay
		}).then((result)=>{
			resolve(result);
		}).catch((err)=>{
			reject(err);
		})
	})
}

export function editStore(uid, name, email, password, lat, lng, drivers, value, dueDay){
	return new Promise((resolve, reject)=>{
		const editStore = httpsCallable(functions, 'editStore');

		editStore({
			'uid': uid,
			'name':name, 
			'email': email,
			'password': password,
			'lat': lat,
			'lng': lng,
			'drivers': drivers,
			'valueInCents': value,
			'dueDay': dueDay
		}).then((result)=>{
			resolve(result);
		}).catch((err)=>{
			reject(err);
		})
	})
}

export function removeStore(uid, secret){
	return new Promise((resolve, reject)=>{
		const deleteStore = httpsCallable(functions, 'deleteStore');

		deleteStore({
			'uid':uid,
			'secret': secret
		}).then((result)=>{
			resolve(result);
		}).catch((err)=>{
			reject(err);
		})
	})
}

export function pay(uid){
	return new Promise((resolve, reject)=>{
		const subscribePayment = httpsCallable(functions, 'subscribePayment');

		subscribePayment({
			'uid':uid
		}).then((result)=>{
			resolve(result);
		}).catch((err)=>{
			reject(err);
		})
	})
}