<template>
	<div>
		<strong class="form-title" v-if="title">{{title}}</strong>
		<select type="text" class="form-control form-control-rf -select" v-model="val" @change="changeOption" :disabled="disable" v-on:focus="focusInput" v-on:blur="blurInput" :data-ref="input_id" :ref="input_id">
			<option v-for="option in options" :value="option.value">{{option.label}}</option>
		</select>
	</div>
</template>
<script>
    export default {
        name: 'FormSelect',
        props:{
            value: '',
            options:[],
            title: null,
            disable:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                val : null,
                focusElementStyle: null,
                isInputFocused: false,
                input_id:  Utils.generateId()
            }
        },
        mounted() {
            this.val = this.value;
        },
        watch: {
            value:function() {
                this.val = this.value;
            }
        },

        methods:{
            changeOption(event){
                this.$emit('input', event.target.value);
                this.$emit('updateOption');
            },
            focusInput (e) {
                this.isInputFocused = true;
                let targetRef = e.target.dataset.ref;
                let target = this.$refs[targetRef];
                this.focusElementStyle = {
                    width: `${target.offsetWidth}px`,
                    height: `${target.offsetHeight}px`,
                    transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
                }
            },
            blurInput() {
                let vm = this;
                setTimeout(() => {
                    if (!vm.isInputFocused) {
                        vm.focusElementStyle = null;
                    }
                }, 300);
                vm.isInputFocused = false;
            },
        }
    }
</script>
<style type="text/css">

</style>