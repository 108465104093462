<template>
	<Modal ref="Modal">
		<template v-slot:header>
			<span class="modal-title"><strong>Nova conta</strong></span>
			<Button class="ms-2" title="Salvar" color="green" @action="save()" :line="true" />
		</template>
		<template v-slot:body>
			<div>
				<div class="d-flex">
					<FormInput title="Nome" class="me-1 flex-fill" v-model="name" />
					<FormInput class="ms-1 flex-fill" title="Email" type="email" v-model="email" />
				</div>
				<div class="d-flex mt-2">
					<FormInput class="me-1 flex-fill" title="Senha" type="password" v-model="password" />
					<FormSelect class="ms-1 flex-fill" :options="roleOptions" title="Função" v-model="role" />
				</div>
			</div>
		</template>
	</Modal>
</template>
<script>
	import Modal from "@/components/Modal"
	import FormInput from '@/components/FormInput'
	import FormSelect from '@/components/FormSelect'
	import Button from '@/components/Button'
	import * as AccountServices from '@/services/AccountServices'

	export default {
		name: "ModalNewAccount",
		components: { Modal, FormInput, Button, FormSelect },

		data(){
			return {
				name: null,
				email: null,
				password: null,
				role: 'admin',
				roleOptions: [ new Utils.FormSelectOptions("Admin", 'admin')
					],
				_resolve: null,
				_reject: null,
				uid: null
			}
		},
		methods:{
			showModal(account = null){
				let self = this;
				if(account){
					this.name = account.displayName;
					this.email = account.email;
					this.password = null;
					this.role = account.role;
					this.uid = account.uid;
				}
				return new Promise((resolve, reject)=>{
					self.$refs.Modal.show();
					self._resolve = resolve;
					self._reject = reject;
				});		
			},
			save() {
				if(this.uid){
					this.edit();
				} else{
					this.create();
				}
			},
			create(){
				let self = this;
				this.$refs.Modal.showLoading();
				AccountServices.createAccount(this.name, this.email, this.password, this.role).then((result)=>{
					self.$refs.Modal.closeLoading();
					self.$refs.Modal.hide();
					Utils.showSnackbarSuccess(result.data.message);
					self.clear();
					if(self._resolve)
						self._resolve();
				}).catch((err)=>{
					self.$refs.Modal.closeLoading();
					Utils.showDialogError(err.message);
				});
			},
			edit(){
				let self = this;
				this.$refs.Modal.showLoading();
				AccountServices.editAccount(this.uid, this.name, this.email, this.password, this.role).then((result)=>{
					self.$refs.Modal.closeLoading();
					self.$refs.Modal.hide();
					Utils.showSnackbarSuccess(result.data.message);
					self.clear();
					if(self._resolve)
						self._resolve();
				}).catch((err)=>{
					self.$refs.Modal.closeLoading();
					Utils.showDialogError(err.message);
				});
			},
			clear(){
				this.name = null;
				this.email = null;
				this.password = null;
				this.role = 'admin';
				this.uid = null;
			}
		}
	}
</script>