export class GenericTableColumn {
    constructor(field, name, sortable = false, width = 'unset', align = "left", action = null) {
        this.field = field;
        this.name = name;
        this.sortable = sortable;
        this.width = width;
        this.align = align;
        this.action = action;
    }
}

export class GenericTableActionColumn {
    constructor(name,action = null, condition = null, tooltip = null, ...icons) {
        this.field = '';
        this.name = name;
        this.sortable = false;
        this.width = 'unset';
        this.align = 'center';
        this.action = null;
        this.buttonAction = action;
        this.buttonCondition = condition;
        this.buttonTooltip = tooltip;
        this.icons = icons;
    }
}

export class FormSelectOptions {
    constructor(label,value) {
        this.label = label;
        this.value = value;
    }
}


export function isObjectEmpty(obj) {
  for(var prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
  }
}
return JSON.stringify(obj) === JSON.stringify({});
}

export function generateId() {
    return Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
}

export function showLoading(identity = '.content') {
    $(identity).css({ "pointer-events": "none" });
    $(identity).first().prepend('<div class="loading_pane"><div class="d-flex justify-content-center align-items-center loading"><span style="margin:5px">Carregando...</span><div style="color:var(--black-rf)"><i class="fa-solid fa-2x fa-circle-notch fa-spin" style="--fa-animation-duration: 1s;"></i></div></div></div>');
}

export function closeLoading(identity = '.content') {
    $(identity).css({ "cursor": "unset", "pointer-events": "unset" });
    if (identity == null)
        $('.loading_pane').remove();
    else
        $(identity).children('.loading_pane').remove();
}
export function amountFromMoney(val) {
    if (typeof val !== 'undefined') {
        var cleanValue = val.toString();
        cleanValue = cleanValue.replace(',','.');
        cleanValue = cleanValue.replace(/[^\d|^.]/g,'');
        return cleanValue;
    }
    return 0;
}

export function formatMoney(val) {
    if (typeof val !== 'undefined' && val !== null) {
        var signal = '';
        if(val < 0)
            signal = '-';
        var cleanValue = val.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        cleanValue = cleanValue.replace('.','');
        cleanValue = cleanValue.replace(',','.');
        cleanValue = cleanValue.replace(/[^\d|^.]/g,'');

        var dotPosition = cleanValue.lastIndexOf('.');
        if(dotPosition != -1) {
            var first = cleanValue.substring(0, dotPosition);
            var seccond = cleanValue.substring(dotPosition+1);
            if(seccond.length > 2) {
                first = first += seccond.substring(0,1);
                seccond = seccond.substring(1);
            } else if(seccond.length < 2) {
                seccond = first.substring(first.length-1, first.length)+seccond;
                first = first.substring(0, first.length-1);
            }
            if(first.length == 0)
                first = '0';
            else
                first = Number(first);
            return signal + first+','+seccond;
        } else {
            cleanValue = Number(cleanValue).toString();
            if(cleanValue.length == 0) {
                return '0,00';
            } else if(cleanValue.length == 1) {
                return signal + '0,0'+cleanValue;
            } else if(cleanValue.length == 2) {
                return signal + '0,'+cleanValue;
            } else {
                return signal + cleanValue.substring(0,cleanValue.length-2)+','+cleanValue.substring(cleanValue.length-2,cleanValue.length);
            }
        }
    }
    return null;
}

export function formatMoneyWithPoint(val) {
    if (typeof val !== 'undefined' && val !== null) {
        var signal = '';
        if(val < 0)
            signal = '-';
        var cleanValue = val.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        cleanValue = cleanValue.replace('.','');
        cleanValue = cleanValue.replace(',','.');
        cleanValue = cleanValue.replace(/[^\d|^.]/g,'');

        var dotPosition = cleanValue.lastIndexOf('.');
        if(dotPosition != -1) {
            var first = cleanValue.substring(0, dotPosition);
            var seccond = cleanValue.substring(dotPosition+1);
            if(seccond.length > 2) {
                first = first += seccond.substring(0,1);
                seccond = seccond.substring(1);
            } else if(seccond.length < 2) {
                seccond = first.substring(first.length-1, first.length)+seccond;
                first = first.substring(0, first.length-1);
            }
            if(first.length == 0)
                first = '0';
            else
                first = Number(first);
            return signal + first.toLocaleString('pt-br')+','+seccond;
        } else {
            cleanValue = Number(cleanValue).toString();
            if(cleanValue.length == 0) {
                return '0,00';
            } else if(cleanValue.length == 1) {
                return signal + '0,0'+cleanValue;
            } else if(cleanValue.length == 2) {
                return signal + '0,'+cleanValue;
            } else {
                return signal + cleanValue.substring(0,cleanValue.length-2).toLocaleString('pt-br')+','+cleanValue.substring(cleanValue.length-2,cleanValue.length);
            }
        }
    }
    return null;
}
export function formatPhone(phone){
    if(phone == null)
        return ''
    var cleanPhone = phone.replace(/[^0-9]/g,'');

    var ddd;
    var first;
    var seccond = '';
    if(cleanPhone.length == 0)
        return '';

    if(cleanPhone[0] == '0') {
        ddd = cleanPhone.substring(1,Math.min(3,cleanPhone.length));
        cleanPhone = cleanPhone.substring(1);
    } else {
        ddd = cleanPhone.substring(0,Math.min(2,cleanPhone.length));
    }

    if(cleanPhone.length-ddd.length > 4) {
        seccond = cleanPhone.substring(cleanPhone.length-4,cleanPhone.length);
    }

    first = cleanPhone.substring(ddd.length,cleanPhone.length-seccond.length);

    return '('+ddd+(first.length>0?') '+first:'')+(seccond.length>0?'-'+seccond:'');

}

export function formatCnpj(cnpj){
    if(cnpj == null)
        return ''
    var cleanCnpj = cnpj.replace(/[^0-9]/g,'');

    var first;
    var seccond = '';
    var third = '';
    var fourth = '';
    var fifth = '';
    if(cleanCnpj.length == 0)
        return '';
    if(cleanCnpj.length <= 14){
        first = cleanCnpj.substring(0, Math.min(2,cleanCnpj.length));
        if(first.length == 2)
            seccond = cleanCnpj.substring(first.length, Math.min(5, cleanCnpj.length));
        if(seccond.length == 3)
            third = cleanCnpj.substring(first.length + seccond.length, Math.min(8, cleanCnpj.length));
        if(third.length == 3)
            fourth = cleanCnpj.substring(first.length + seccond.length + third.length, Math.min(12, cleanCnpj.length));
        if(fourth.length == 4)
            fifth = cleanCnpj.substring(first.length + seccond.length + third.length + fourth.length, Math.min(14, cleanCnpj.length));
        return (first.length>0?first:'')+(seccond.length>0?'.'+seccond:'')+(third.length>0?'.'+third:'')+(fourth.length>0?'/'+fourth:'')+(fifth.length>0?'-'+fifth:'');
    }

    else {
        return cleanCnpj.substring(0, 2) +'.'+ cleanCnpj.substring(2, 5) +'.'+ cleanCnpj.substring(5, 8) +'/'+ cleanCnpj.substring(8, 12) +'-'+ cleanCnpj.substring(12, 14);
    }
    
}

export function formatCpf(cpf){
    if(cpf == null)
        return ''

    var cleanCpf = cpf.replace(/[^0-9]/g,'');

    var first;
    var seccond = '';
    var third = '';
    var fourth = '';
    if(cleanCpf.length == 0)
        return '';
    if(cleanCpf.length <= 11){
        first = cleanCpf.substring(0, Math.min(3,cleanCpf.length));
        if(first.length == 3)
            seccond = cleanCpf.substring(first.length, Math.min(6, cleanCpf.length));
        if(seccond.length == 3)
            third = cleanCpf.substring(first.length + seccond.length, Math.min(9, cleanCpf.length));
        if(third.length == 3)
            fourth = cleanCpf.substring(first.length + seccond.length + third.length, Math.min(11, cleanCpf.length));
        return (first.length>0?first:'')+(seccond.length>0?'.'+seccond:'')+(third.length>0?'.'+third:'')+(fourth.length>0?'-'+fourth:'');
    }
    else {
        return cleanCpf.substring(0, 3) +'.'+ cleanCpf.substring(3, 6) +'.'+ cleanCpf.substring(6, 9) +'-'+ cleanCpf.substring(9, 11);
    }
    
}

export function formatCnpjOrCpf(number){
    if(number == null)
        return ''
    var clean = number.replace(/[^0-9]/g,'');
    if(clean.length == 0)
        return '';
    if(clean.length > 11){
        return formatCnpj(clean);
    } else {
        return formatCpf(clean);
    }

}
export function showDialogSuccess(_message){
    bootbox.dialog({
        message: '<div class="dialog--rf-content"><div class="icon--dialog-success"><i class="fa-solid fa-circle-check"></i></div><div class="text-dialog--content"><div><strong>Sucesso</strong></div><div><span>'+_message+'</span></div></div></div>',
        centerVertical: true,
        className: 'animate__animated animate__bounceIn',
        closeButton: false,
        backdrop: true,
        onEscape: true,
        buttons:{
            ok:{
                label: "Ok",
                className: 'btn button--green button--ok',
            }
        }
    })
}
export function showDialogError(_message){
    bootbox.dialog({
        message: '<div class="dialog--rf-content"><div class="icon--dialog-error"><i class="fa-solid fa-circle-exclamation"></i></div><div class="text-dialog--content"><div><strong>Erro</strong></div><div><span>'+_message+'</span></div></div></div>',
        centerVertical: true,
        className: 'animate__animated animate__bounceIn',
        closeButton: false,
        backdrop: true,
        onEscape: true,
        buttons:{
            ok:{
                label: "Ok",
                className: 'btn button--green button--ok',
            }
        }
    })
}

export function showDialogConfirm(_message, _yes='Sim',_no='Não'){
    return new Promise((resolve, reject)=>{
        bootbox.confirm({
            centerVertical: true,
            className: 'animate__animated animate__bounceIn',
            message: '<div class="dialog--rf-content"><div class="icon--dialog-confirm"><i class="fa-solid fa-circle-question"></i></div><div class="text-dialog--content"><div><span>'+_message+'</span></div></div></div>',
            buttons: {
                confirm: {
                    label: _yes,
                    className: 'btn button--line-green'
                },
                cancel: {
                    label: _no,
                    className: 'btn button--line-red'
                }
            },
            callback: function(result){
                if(result){
                    resolve();
                } else{
                    reject();
                } 
            }
        });
    })
}

export function showDialogInfo(_message){
    bootbox.dialog({
        message: '<div class="dialog--rf-content"><div class="icon--dialog-confirm"><i class="fa-regular fa-question-circle"></i></div><div class="text-dialog--content"><div><span>'+_message+'</span></div></div></div>',
        centerVertical: true,
        className: 'animate__animated animate__bounceIn',
        closeButton: false,
        backdrop: true,
        onEscape: true,
        buttons:{
            ok:{
                label: "Ok",
                className: 'btn button--blue button--ok',
            }
        }
    })
}

export function showDialogPrompt(_message, _yes='Ok',_no='Cancelar') {
    return new Promise((resolve, reject)=>{
        bootbox.prompt({
            centerVertical: true,
            className: 'animate__animated animate__bounceIn',
            title: '<div class="dialog--rf-content"><div class="text-dialog--content"><div><span>'+_message+'</span></div></div></div>',
            buttons: {
                confirm: {
                    label: _yes,
                    className: 'btn button--green'
                },
                cancel: {
                    label: _no,
                    className: 'btn button--red'
                }
            },
            callback: function(result){
                if(result){
                    resolve(result);
                } else{
                    reject();
                } 
            }
        });
    })
}

export function showSnackbarSuccess(message){
    Snackbar.show({
        text: `<span>${message}</span>`, 
        pos: 'bottom-center', 
        showAction: true,
        actionText: `<i class="fa-solid fa-thumbs-up"></i>`,
        actionTextColor: '#FFF',
        backgroundColor: 'var(--green-rf)'
    });
}

export function showSnackbarError(message) {
    Snackbar.show({
        text: `<i class="fa-solid fa-circle-exclamation"></i> <span>${message}</span>`, 
        pos: 'bottom-center', 
        actionText: '<i class="fa-solid fa-xmark"></i>',
        backgroundColor: 'var(--red-rf)',
        actionTextColor: '#FFF'
    });
}
export function toRadians(degrees) {
    return degrees * (Math.PI / 180);
}

export function toDegrees(radians) {
    return radians * (180 / Math.PI);
}

export function haversineDistance(lat1Rad, lon1Rad, lat2Rad, lon2Rad) {
    const R = 6371000; // Earth's radius in meters

    // Differences in coordinates
    const deltaLat = lat2Rad - lat1Rad;
    const deltaLon = lon2Rad - lon1Rad;

    // Haversine formula
    const a = Math.sin(deltaLat / 2) ** 2 +
              Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in meters
    const distance = R * c;

    return distance;
}
export function isMobile(){
    let check = false;
    (
        function(a) {
            if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
                check = true;
        })
    (navigator.userAgent||navigator.vendor||window.opera);
    return check;
};