const MOVE_SETPS = 30
const MOVE_TIME_IN_SEC = 1
const DISTANCE_THRESHOLD = 200

export default class {
	constructor(mark, name) {
		this.nextPosition = null;
		this.mark = mark;
		this.name = name;
	}

	position() {
		return this.mark.position;
	}

	setMap(map) {
		this.mark.setMap(map);
	}

	setPosition(position) {
		this.mark.setPosition(position);
	}

	move(lat, lng) {
		this.nextPosition = [lat,lng];
		this.calculateMovement();
	}

	calculateMovement() {
		var position = this.mark.position;

		const lat1Rad = Utils.toRadians(position.lat());
		const lon1Rad = Utils.toRadians(position.lng());
		const lat2Rad = Utils.toRadians(this.nextPosition[0]);
		const lon2Rad = Utils.toRadians(this.nextPosition[1]);

		this.distance = Utils.haversineDistance(lat1Rad, lon1Rad, lat2Rad, lon2Rad);
		if(this.distance > DISTANCE_THRESHOLD) {
			this.setPosition({'lat':this.nextPosition[0],'lng': this.nextPosition[1]});
			if(this.timeout != null) {
				clearInterval(this.timeout);
				this.timeout = null;
			}
			return;
		}
		this.distance /= MOVE_SETPS;

		const deltaLon = lon2Rad - lon1Rad;

		const x = Math.sin(deltaLon) * Math.cos(lat2Rad);
		const y = Math.cos(lat1Rad) * Math.sin(lat2Rad) - Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(deltaLon);
		const initialBearingRad = Math.atan2(x, y);

		let initialBearingDeg = initialBearingRad * (180 / Math.PI);

		if (initialBearingDeg < 0) {
			initialBearingDeg += 360;
		}

		this.moveDegree = initialBearingDeg;

		if(this.timeout == null) {
			var self = this;
			this.timeout = setInterval(function() {
				self.updateMoviment();
			},MOVE_TIME_IN_SEC/MOVE_SETPS*1000);
		}
	}

	updateMoviment() {
		var position = this.mark.position;
		const lat1 = position.lat();
		const lon1 = position.lng();

		const R = 6371000;

		const lat1Rad = Utils.toRadians(lat1);
		const lon1Rad = Utils.toRadians(lon1);
		const bearingRad = Utils.toRadians(this.moveDegree);

		const lat2Rad = Math.asin(Math.sin(lat1Rad) * Math.cos(this.distance / R) +
			Math.cos(lat1Rad) * Math.sin(this.distance / R) * Math.cos(bearingRad));

		const lon2Rad = lon1Rad + Math.atan2(Math.sin(bearingRad) * Math.sin(this.distance / R) * Math.cos(lat1Rad),
			Math.cos(this.distance / R) - Math.sin(lat1Rad) * Math.sin(lat2Rad));

		const lat2 = Utils.toDegrees(lat2Rad);
		const lon2 = Utils.toDegrees(lon2Rad);

		this.setPosition({'lat':lat2,'lng': lon2});

		const flat2Rad = Utils.toRadians(this.nextPosition[0]);
		const flon2Rad = Utils.toRadians(this.nextPosition[1]);
		const distanceNow = Utils.haversineDistance(lat1Rad, lon1Rad, flat2Rad, flon2Rad);
		if(distanceNow < this.distance) {
			clearInterval(this.timeout);
			this.timeout = null;
		}

	}
}