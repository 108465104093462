<template>
	<Modal ref="Modal">
		<template v-slot:header>
			<span>Pagamento via pix</span>
		</template>
		<template v-slot:body>
			
		</template>
	</Modal>
</template>
<script>
	import Modal from '@/components/Modal'
	export default {
		name: "ModalPayPix",
		components: { Modal },

		mounted(){
			let self = this;
			EventBus.$on('OPEN_MODAL_PIX', function (data) {
				self.showModal();
			});
			EventBus.$on('CLOSE_MODAL_PIX', function (data) {
				self.closeModal();
			});
		},

		methods: {
			showModal(){
				this.$refs.Modal.show();
			}
		}
	}

</script>