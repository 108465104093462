<template>
	<div>
		<strong class="form-title" v-if="title">{{title}}</strong>
		<div class="rf-input-icon rf-input-icon--left form-group-search input-group">
			<input type="text" class="form-control form-control-rf form-control-rf-search" :value="value" @input="handleInput" :placeholder="placeholder" @keyup.enter="updateModel()" :disabled="disable" v-on:focus="focusInput" v-on:blur="blurInput"  :data-ref="input_id" :ref="input_id">
			<div class="input-group-append">
				<button class="btn btn-search" @click="updateModel()" :disabled="disable"><i class="fa-solid fa-magnifying-glass"></i></button>
			</div>
			<div class="rf-input-icon__icon rf-input-icon__icon--left">
				<span v-if="value == null || value == ''">
					<i class="fa-solid fa-magnifying-glass"></i>
				</span>
				<span v-else-if="!disable" @click="erase" class="button--erase">
					<i class="fa-solid fa-xmark"></i>
				</span>
			</div>
		</div>	
	</div>
</template>
<script>
	export default {
		name: 'FormInputSearch',
		props:{
			value: '',
			placeholder:'',
			title:'',
			disable: {
			default: false,
				type : Boolean
			},
			autoSearch: {
			default: true,
				type: Boolean
			}
		},
		data() {
			return {
				currentTimeout:null,
				focusElementStyle: null,
				isInputFocused: false,
				input_id:  Utils.generateId()
			}
		},
		watch: {
			value: function(old, newValue) {
				if(old == newValue)
					return;
				if(!this.value || this.value == '') {
					this.erase();
				} else {
					this.runTimeout();
				}
			},
		},
		methods:{
			runTimeout() {
				if(!this.autoSearch)
					return;
				if(this.currentTimeout) {
					clearTimeout(this.currentTimeout);
					this.currentTimeout = null;
				}
				this.currentTimeout = setTimeout(this.updateModel,500);
			},
			handleInput(event) {
				this.$emit('input', event.target.value);
			},
			updateModel(){
				if(this.currentTimeout) {
					clearTimeout(this.currentTimeout);
					this.currentTimeout = null;
				}
				this.$emit('updateModel');
			},
			erase(event){
				if(this.currentTimeout) {
					clearTimeout(this.currentTimeout);
					this.currentTimeout = null;
				}
				this.$emit('input','');
				this.updateModel();
			},
			focusInput (e) {
				this.isInputFocused = true;
				let targetRef = e.target.dataset.ref;
				let target = this.$refs[targetRef];
				this.focusElementStyle = {
					width: `${target.offsetWidth}px`,
					height: `${target.offsetHeight}px`,
					transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
				}
			},
			blurInput() {
				let vm = this;
				setTimeout(() => {
					if (!vm.isInputFocused) {
						vm.focusElementStyle = null;
					}
				}, 300);
				vm.isInputFocused = false;
			},
		}

	}
</script>
<style type="text/css">

	.form-control-rf-search{
		padding-left: 2.8rem!important;
	}
	.form-control-rf-search-sm {
		max-width: 180px;
		margin-right: 0px;
	}
	.rf-input-icon {
		position: relative!important;
		width: 100%!important;

	}
	.rf-input-icon>.rf-input-icon__icon>span {
		display: table;
		height: 100%;
		width: 100%;
		color:#808080;
	}
	.rf-input-icon>.rf-input-icon__icon>span>i {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		line-height: unset;
	}
	.rf-input-icon>.rf-input-icon__icon {
		position: absolute;
		height: 100%;
		display: inline-block;
		text-align: center;
		top: 0;
		width: 3.2rem;
		z-index: 2;
	}
	.rf-input-icon>.rf-input-icon__icon.rf-input-icon__icon--left {
		left: 0;
		z-index: 4;
	}
	.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn.btn-search{
		border-top-right-radius: 0.75rem!important;
		border-bottom-right-radius: 0.75rem!important;
		width: 50px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.btn.btn-search{
		color: #fff;
		background: #0E78F9;
		height: 40px;
		padding: unset;
	}
	.btn.btn-search:hover{
		color: #fff;
		background: #0762d1;
	}
	.button--erase{
		cursor: pointer;
	}
	.form-control-rf-search:focus {
		z-index: 1!important;
	}
</style>